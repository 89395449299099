@use "@angular/material" as mat;
@use "src/variables.scss";
@include mat.core();
// @import "~@angular/material/theming";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import "~@angular/material/theming";

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
// @include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

:root {
  --mdc-filled-text-field-error-label-text-color: #f44336 !important;
  --mdc-checkbox-selected-icon-color: transparent;
  --mat-app-primary: #2751a5;
  --app-red: #cd252d;
}

$primary-background-color: #2751a5;
$primary-text-color: #ffffff;

$secondary-text-color: #333333;
$primary-headers-color: #46535b;

$default-color: #000000;

$mat-form-field-container-height: 50px;

$indigo-palette: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #2751a5,
  600: #2751a5,
  700: #2751a5,
  800: #2751a5,
  900: #2751a5,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  ),
);
///////////////////////////////
$mat-sinapsis-provider-primary: (
  50: #ffffff,
  100: rgb(228, 228, 228),
  200: rgb(216, 215, 215),
  300: #65656b,
  400: #44444d,
  500: #23232d,
  600: #1f1f28,
  700: #1a1a22,
  800: #15151c,
  900: #0c0c11,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: white,
    A200: white,
    A400: white,
    A700: white,
  ),
);

$sinapsis-provider-primary: mat.m2-define-palette(
  $mat-sinapsis-provider-primary
);

$sinapsis-provider-accent: mat.m2-define-palette(
  $mat-sinapsis-provider-primary,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$sinapsis-provider-warn: mat.m2-define-palette(
  $mat-sinapsis-provider-primary,
  200,
  100,
  900
);

// Create the theme object (a Sass map containing all of the palettes).
$sinapsis-provider-theme: mat.m2-define-light-theme(
  $sinapsis-provider-primary,
  $sinapsis-provider-accent,
  $sinapsis-provider-warn
);
//////////////////////////////////
$sinapsis-provider-frontend-primary: mat.m2-define-palette($indigo-palette);

$sinapsis-provider-frontend-accent: mat.m2-define-palette(
  mat.$m2-pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$sinapsis-provider-frontend-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$sinapsis-provider-frontend-theme: mat.m2-define-light-theme(
  $sinapsis-provider-frontend-primary,
  $sinapsis-provider-frontend-accent,
  $sinapsis-provider-frontend-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-legacy-component-themes($sinapsis-provider-frontend-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: #f8f8f8;
}

/* Palette generated by Material Palette - materialpalette.com/light-blue/amber */

/* Import font family */
@font-face {
  font-family: "nunitoregular";
  src: url("../src/assets/fonts/nunito-bold-webfont.woff") format("woff2"),
    url("../src/assets/fonts/nunito-bold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "nunitoregular";
  src: url("../src/assets/fonts/nunito-light-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/nunito-light-webfont.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "nunitoregular";
  src: url("../src/assets/fonts/nunito-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/nunito-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.mat-icon.mat-warn {
  --mat-icon-color: var(--app-red) !important;
}

.mat-raised-button {
  background-color: $primary-background-color !important;
  color: $primary-text-color !important;
}

.mat-stroked-button {
  background-color: #fff !important;
  color: #2751a5 !important;
  border: 1px solid #2751a5 !important;
}

.my-form-field.mat-form-field-appearance-legacy .mat-form-field-underline,
.my-form-field.mat-form-field-appearance-legacy .mat-form-field-ripple,
.my-form-field.mat-form-field-appearance-legacy.mat-focused
  .mat-form-field-underline,
.my-form-field.mat-form-field-appearance-legacy.mat-focused
  .mat-form-field-ripple {
  background-color: #c4c4c4;
}

.mat-snack-bar-container.single {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

.mat-snack-bar-container.multi {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}
.mat-snack-bar-container.success {
  color: #43535c !important;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
}

$sinapsis-provider-new-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    density: (
      scale: 0,
    ),
  )
);

app-dashboard {
  @include mat.progress-bar-theme($sinapsis-provider-new-theme);
}

.custom-tooltips {
  font-size: 12px;
  font-family: "Nunito", sans-serif;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.2);
  cursor: help;

  .mat-mdc-tooltip-surface {
    background-color: #ffffff !important;
    color: #46535b !important;
    font-size: 12px;
    font-family: "Nunito", sans-serif;
  }
}

.remove-border-bottom .mat-tab-header {
  border-bottom: none;
  margin: 0.5% 0 1.5% 0;
  .mat-tab-label {
    font-family: "Nunito", sans-serif;
    color: #2751a5 !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  .mat-tab-labels {
    justify-content: center;
  }
  .mat-ink-bar {
    background-color: #2751a5 !important;
  }
}

.chart-navigation {
  .mat-mdc-tab-label-container {
    font-family: "Nunito", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    border-bottom: none;

    .mat-mdc-tab .mdc-tab-indicator__content--underline {
      border-color: #2751a5 !important;
    }

    .mdc-tab__text-label {
      color: #2751a5 !important;
    }

    // margin: 0.5% 0 1.5% 0;
    .mat-tab-labels {
      justify-content: center;
    }
    .mat-ink-bar {
      background-color: #2751a5 !important;
    }
  }
}

.provider-name {
  .mat-mdc-tab-body-wrapper {
    display: none !important;
  }

  .mat-mdc-tab .mdc-tab-indicator__content--underline {
    border-color: var(--primary-color, #cd252d) !important;
  }
}

.mat-tab-body-wrapper {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
@include mat.all-component-themes($sinapsis-provider-theme);

/*********ESTILOS GENERALES SELECT**************** */

div.mat-mdc-select-panel {
  max-height: 407px !important;

  font-size: 15px;
  font-family: Nunito, sans-serif;
}

.select-provider-form {
  // width: 280px !important;

  mat-select {
    display: none !important;
  }

  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: unset !important;
    padding-bottom: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    min-height: auto !important;
  }

  .mdc-line-ripple {
    display: none !important;
  }

  .mat-mdc-text-field-wrapper {
    height: 48px !important;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: calc(50px / 2) !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    border-radius: 5px !important;
    border: 1px solid #808080a3 !important;
    background: #fff !important;
    height: 48px !important;
  }

  mat-select-trigger {
    font-size: 14px !important;
    font-family: "Nunito", sans-serif;
    color: black;
  }
}

.select-provider-form-2 {
  // width: 280px !important;

  mat-select {
    display: none !important;
  }

  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding-top: 11px !important;
  }

  // .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  //   top: calc(50px /2) !important;
  // }

  .mdc-line-ripple {
    display: none !important;
  }

  /*.mat-mdc-text-field-wrapper {
    height: 48px !important;
  }*/

  /*.mdc-text-field--filled:not(.mdc-text-field--disabled).mdc-text-field--invalid .mdc-floating-label {
    color: #f44336 !important;
  }*/

  .mat-mdc-select,
  .custom-label,
  mat-select-trigger {
    font-size: 14px !important;
    font-family: "Nunito", sans-serif;
    color: white;
  }

  .mdc-text-field--invalid {
    color: #f44336 !important;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    border-radius: 5px !important;
    border: 1px solid #808080a3 !important;
    background-color: #244791 !important;
    height: 48px !important;
  }
}
/**********FIN ESTILOS GENERALES SELECT************ */

/**********ESTILOS GENERALES GRAFICOS POR PESTAÑA************ */

mat-tab-body {
  margin-top: 22px !important;
}

::ng-deep .mat-tab-group.mat-primary .mat-tab-label.mat-tab-label-active {
  border-bottom: 2px solid #ff4081; /* Cambia #ff4081 al color deseado */
}

::ng-deep .mat-tab-group.mat-primary .mat-ink-bar {
  background-color: #ff4081; /* Cambia #ff4081 al color deseado */
}

.add-user-form {
  .mat-mdc-form-field-error-wrapper {
    padding: unset !important;
  }
}

.mat-mdc-dialog-title {
  padding: unset !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  padding: 4px !important;
}

.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  color: #000 !important;
  background-color: #fff !important;
}

.mat-step-icon-selected {
  background-color: #2751a5 !important;
}

mat-icon.mat-primary {
  color: #2751a5 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.form-login {
  .mdc-text-field,
  .mat-mdc-form-field-error-wrapper {
    padding: unset !important;
  }
}

.mat-mdc-checkbox-checked .mdc-checkbox__background {
  border-color: var(--mat-app-primary) !important;
  background-color: var(--mat-app-primary) !important;
}

.add-user-form {
  .mdc-text-field--filled {
    font-size: 14px !important;
  }
}

.disabled-member {
  .mat-mdc-raised-button:not(:disabled) {
    background-color: var(--mat-app-primary) !important;
    color: #fff !important;
  }
}

//RECUPERAR CONTRASEÑA
.forgot-form,
.reset-form {
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }
}

table {
  td {
    border: 1px solid transparent !important;
  }
}

.mat-mdc-menu-content {
  padding: 0px !important;
}

@media (max-height: 700px) {
  .mat-mdc-menu-item {
    min-height: 40px !important;
  }
}

tr.mat-row {
  background: #79a1d8 !important;
  border: 1px solid #ff0000 !important;
}

.mat-mdc-menu-item {
  min-height: 40px !important;
}
.mat-mdc-menu-item .mat-icon {
  height: auto !important;
}
